<script setup lang="ts">
import BtnTemplate from './template.vue'

type Props = {
  disabled?: boolean
  small?: boolean
  isLink?: boolean
  dataTestId?: string
}
const {
  disabled = false,
  small = false,
  isLink = false,
  dataTestId,
} = defineProps<Props>()

type Emits = {
  click: [MouseEvent]
}
const emit = defineEmits<Emits>()

const handleClick = (event: MouseEvent): void => {
  if (!disabled) emit('click', event)
}
</script>

<template>
  <BtnTemplate
    class="main"
    :class="{ disabled, small }"
    :is-link="isLink"
    :data-test-id="dataTestId"
    v-bind="$attrs"
    @click="handleClick"
  >
    <span class="btnText">
      <slot />
    </span>
  </BtnTemplate>
</template>
